










import { Component, Vue } from 'vue-property-decorator'
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import ListItem from '@tiptap/extension-list-item'
//import Icons from '@bertazzoni/common/src/components/Icons.vue'
import MenuItem from './MenuItem.vue'

@Component({
  components: { EditorContent, MenuItem }
})
export default class RichText extends Vue {
  private editor = new Editor({
    content: '',
    extensions: [
      StarterKit,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph']
      }),
      Highlight,
      Link,
      ListItem
    ],
    editorProps: {
      attributes: {
        class: 'border border-gray-400 w-nav-space p-2 focus:outline-none focus:border-redUnderline'
      }
    }
  })
  created() {
    this.editor.on('update', ({ editor }) => {
      this.$emit('contentChange', editor.getHTML())
    })
  }
  sendNewContent(newHtml: string) {
    this.$emit('contentChange', newHtml)
  }
  beforeDestroy() {
    this.editor.destroy()
  }
  data() {
    return {
      items: [
        {
          icon: 'bold',
          title: 'Bold',
          action: () =>
            this.editor
              .chain()
              .focus()
              .toggleBold()
              .run(),
          isActive: () => this.editor.isActive('bold')
        },
        {
          icon: 'italic',
          title: 'Italic',
          action: () =>
            this.editor
              .chain()
              .focus()
              .toggleItalic()
              .run(),
          isActive: () => this.editor.isActive('italic')
        },
        {
          icon: 'strikethrough',
          title: 'Strike',
          action: () =>
            this.editor
              .chain()
              .focus()
              .toggleStrike()
              .run(),
          isActive: () => this.editor.isActive('strike')
        },
        {
          icon: 'code-view',
          title: 'Code',
          action: () =>
            this.editor
              .chain()
              .focus()
              .toggleCode()
              .run(),
          isActive: () => this.editor.isActive('code')
        },
        {
          icon: 'mark-pen-line',
          title: 'Highlight',
          action: () =>
            this.editor
              .chain()
              .focus()
              .toggleHighlight()
              .run(),
          isActive: () => this.editor.isActive('highlight')
        },
        {
          type: 'divider'
        },
        {
          icon: 'h-1',
          title: 'Heading 1',
          action: () =>
            this.editor
              .chain()
              .focus()
              .toggleHeading({ level: 1 })
              .run(),
          isActive: () => this.editor.isActive('heading', { level: 1 })
        },
        {
          icon: 'h-2',
          title: 'Heading 2',
          action: () =>
            this.editor
              .chain()
              .focus()
              .toggleHeading({ level: 2 })
              .run(),
          isActive: () => this.editor.isActive('heading', { level: 2 })
        },
        {
          icon: 'paragraph',
          title: 'Paragraph',
          action: () =>
            this.editor
              .chain()
              .focus()
              .setParagraph()
              .run(),
          isActive: () => this.editor.isActive('paragraph')
        },
        {
          icon: 'list-unordered',
          title: 'Bullet List',
          action: () =>
            this.editor
              .chain()
              .focus()
              .toggleBulletList()
              .run(),
          isActive: () => this.editor.isActive('bulletList')
        },
        {
          icon: 'list-ordered',
          title: 'Ordered List',
          action: () =>
            this.editor
              .chain()
              .focus()
              .toggleOrderedList()
              .run(),
          isActive: () => this.editor.isActive('orderedList')
        },
        {
          icon: 'list-check-2',
          title: 'Task List',
          action: () =>
            this.editor
              .chain()
              .focus()
              .toggleTaskList()
              .run(),
          isActive: () => this.editor.isActive('taskList')
        },
        {
          icon: 'code-box-line',
          title: 'Code Block',
          action: () =>
            this.editor
              .chain()
              .focus()
              .toggleCodeBlock()
              .run(),
          isActive: () => this.editor.isActive('codeBlock')
        },
        {
          type: 'divider'
        },
        {
          icon: 'align-left',
          title: 'Left',
          action: () =>
            this.editor
              .chain()
              .focus()
              .setTextAlign('left')
              .run(),
          isActive: () => this.editor.isActive('left')
        },
        {
          icon: 'align-center',
          title: 'Center',
          action: () =>
            this.editor
              .chain()
              .focus()
              .setTextAlign('center')
              .run(),
          isActive: () => this.editor.isActive('center')
        },
        {
          icon: 'align-justify',
          title: 'Justify',
          action: () =>
            this.editor
              .chain()
              .focus()
              .setTextAlign('justify')
              .run(),
          isActive: () => this.editor.isActive('justify')
        },
        {
          icon: 'align-right',
          title: 'Right',
          action: () =>
            this.editor
              .chain()
              .focus()
              .setTextAlign('right')
              .run(),
          isActive: () => this.editor.isActive('right')
        },
        {
          type: 'divider'
        },
        {
          icon: 'double-quotes-l',
          title: 'Blockquote',
          action: () =>
            this.editor
              .chain()
              .focus()
              .toggleBlockquote()
              .run(),
          isActive: () => this.editor.isActive('blockquote')
        },
        {
          icon: 'separator',
          title: 'Horizontal Rule',
          action: () =>
            this.editor
              .chain()
              .focus()
              .setHorizontalRule()
              .run()
        },
        {
          type: 'divider'
        },
        {
          icon: 'text-wrap',
          title: 'Hard Break',
          action: () =>
            this.editor
              .chain()
              .focus()
              .setHardBreak()
              .run()
        },
        {
          icon: 'format-clear',
          title: 'Clear Format',
          action: () =>
            this.editor
              .chain()
              .focus()
              .clearNodes()
              .unsetAllMarks()
              .run()
        },
        {
          type: 'divider'
        },
        {
          icon: 'arrow-go-back-line',
          title: 'Undo',
          action: () =>
            this.editor
              .chain()
              .focus()
              .undo()
              .run()
        },
        {
          icon: 'arrow-go-forward-line',
          title: 'Redo',
          action: () =>
            this.editor
              .chain()
              .focus()
              .redo()
              .run()
        }
      ]
    }
  }
}
